export default {
    data() {
        return {
            //窗口大小
            screenWidth: '',
            screenHeight: ''
        }
    },
    mounted() {
        const me = this;
        const reSize = function () {
            if (window.innerWidth)
                me.screenWidth = window.innerWidth;
            else if ((document.body) && (document.body.clientWidth))
                me.screenWidth = document.body.clientWidth;
            // 获取窗口高度
            if (window.innerHeight)
                me.screenHeight = window.innerHeight;
            else if ((document.body) && (document.body.clientHeight))
                me.screenHeight = document.body.clientHeight;
        }
        reSize();
        window.onresize = () => {
            return (() => {
                reSize();
            })();
        };
    }
}
