import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/spaceHome.vue'
import axios from "axios";
import {list2singleObject} from '@/base/recursion'

Vue.use(Router)
//解决路由重复跳转报错的问题
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
}
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const childRouter = [];

//获取所有路由
await axios({
    method: 'get',
    url: "/api/authCenter/auth/routerMap",
}).then(res => {
    if (res.data.code === 0) {
        //动态添加左侧路由
        fnAddDynamicMenuRoutes(list2singleObject(res.data.data));
    }
}).catch(err => {
    console.log(err);
});

/**
 * 添加动态(菜单)路由
 * @param menuList 路由
 */

function fnAddDynamicMenuRoutes(menuList = []) {
    let temp = [];
    for (let i = 0; i < menuList.length; i++) {
        let currentMenu = menuList[i];
        if (currentMenu.authUrl) {
            childRouter.push(createRouteConfig(currentMenu));
        }
        if (currentMenu.child && currentMenu.child.length >= 1) {
            temp = temp.concat(currentMenu.child);
        }
    }
    if (temp.length >= 1) {
        return fnAddDynamicMenuRoutes(temp);
    }
}

function createRouteConfig(menu) {
    // 组装路由
    let route = {
        url: menu.authUrl,
        path: '',
        component: null,
        name: '',
        meta: {
            menuId: menu.id,
            title: menu.authName,
            iframeURL: '',
        }
    };
    // eslint-disable-next-line
    let URL = (menu.authUrl || '').replace(/{{([^}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
        route.path = route.name = `i-${menu.id}`
        route.meta.iframeURL = URL
    } else {
        URL = URL.replace(/^\//, '').replace(/_/g, '-')
        route.path = route.name = URL.replace(/\//g, '-') + "-" + menu.id;
        route.component = () =>
            import (`@/components/${menu.routerFilePath}`)
    }
    return route;
}

function isURL(s) {
    return /^http.s?:\/\/.*/.test(s);
}

const router = new Router({
    // base:'/test/',
    // mode:'history',
    routes: [{
        path: '/',
        name: 'spaceHome',
        component: Home,
        children: childRouter,
        meta: {
            title: '奋斗的年轻人'
        },
    }],
});
export default router;
