import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store'
import WindowSize from '@/base/ScreenSize.js'
import router from "@/router";
import _ from 'lodash';
import * as echarts from 'echarts';
import axios from 'axios'
//跳转路由更改页面标题
router.beforeEach((to, from, next) => {
    to.meta.title && (document.title = to.meta.title);
    next()
});

Vue.config.productionTip = false
Vue.use(ElementUI);

//配置全局拦截
axios.interceptors.request.use(
    config => {
        config.headers.token = store.state.token;
        //在此设置全局拦截
        return config
    }, error => {
        return Promise.reject(error)
    });

axios.interceptors.response.use(
    response => {
        //未登录则返回公共首页
        if (response.data.code === 402) {
            router.replace({
                path: '/',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            }).then(() => {
                store.commit('token', '');
                store.commit('userId', '');
                store.commit('userName', '');
                store.commit('phoneNumber', '');
                store.commit('imgUrl', '');
                store.commit('roleList', []);
                store.commit('authList', []);
                store.commit('genshinMainUid', '');
            });
        }
        return response;
    }, error => {
        return Promise.reject(error) // 返回接口返回的错误信息
    });

Vue.prototype.$axios = axios;
Vue.prototype._ = _;
Vue.prototype.$echarts = echarts
Vue.mixin(WindowSize);
Vue.config.productionTip = false;
new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
