<template>
    <div id="app">
        <router-view/>
        <el-footer style="font-size: 10px;height: 15px;">
            <a style="color:blueviolet" href="https://beian.miit.gov.cn/" target="_blank">吉ICP备2022001266号</a>
        </el-footer>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}
</style>
