<template>
    <div>
        <el-container>
            <el-header style="text-align: right; font-size: 20px;height: 50px;">
                <span v-if="loginUserId===''">
                  <el-button round @click="dialogFormVisible=true" type="primary">登录</el-button>
                </span>
                <span v-if="loginUserId!==''">
                  <el-dropdown @command="handleCommand">
                    <i class="el-icon-setting" style="margin-right: 15px"></i>
                      <template v-slot:dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
                          <el-dropdown-item command="logout">登出</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                  </el-dropdown>
                  <span>用户名:{{ loginUserName }}</span>
                </span>
            </el-header>
            <el-container :style="{height: screenHeight-85+'px', border: '1px solid #eee'}">
                <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
                    <el-menu>
                        <el-menu-item index="0" @click="menu('/')">首页</el-menu-item>
                        <el-submenu v-for="menuParent in menuList" :index="menuParent.seq.toString()"
                                    :key="menuParent.id">
                            <template v-slot:title><i :class="menuParent.icon"></i>{{ menuParent.authName }}</template>
                            <el-menu-item-group v-for="menuItem in menuParent.child" :key="menuItem.id">
                                <el-menu-item :index="menuParent.seq+'-'+menuItem.seq"
                                              @click="menu(menuItem.authUrl+'-'+menuItem.id)">
                                    <i :class="menuItem.icon"></i>{{ menuItem.authName }}
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-main>
                    <transition name="el-zoom-in-top">
                        <router-view></router-view>
                    </transition>
                </el-main>
            </el-container>
        </el-container>

        <!--登录对话框-->
        <el-dialog title="登录" :visible.sync="dialogFormVisible" width="30%">
            <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
                <el-form-item label="手机号" label-width="80px" prop="phoneNumber">
                    <el-input v-model="loginForm.phoneNumber" auto-complete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="密码" label-width="80px" prop="password">
                    <el-input show-password v-model="loginForm.password" auto-complete="off" clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="login">登 录</el-button>
                <el-button @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--修改页面对话框-->
        <el-dialog title="修改密码" :visible.sync="changePasswordVisible" width="30%" @close="quitChangePassword()">
            <el-form :model="changePasswordForm" :rules="changePasswordRules" ref="changePasswordForm">
                <el-form-item label="原密码" label-width="80px" prop="oldPassword">
                    <el-input show-password v-model="changePasswordForm.oldPassword" auto-complete="off"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="新密码" label-width="80px" prop="newPassword">
                    <el-input show-password v-model="changePasswordForm.newPassword" auto-complete="off"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="确认密码" label-width="80px" prop="newPasswordCheck">
                    <el-input show-password v-model="changePasswordForm.newPasswordCheck" auto-complete="off" clearable>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changePassword()">确 定</el-button>
                <el-button @click="quitChangePassword()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {list2singleObject} from '@/base/recursion'

export default {
    data() {
        //密码一次确认
        const validatePass = (rule, value, callback) => {
            if (this.changePasswordVisible) {
                if (value === undefined || value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.changePasswordForm.newPasswordCheck !== '') {
                        this.$refs.changePasswordForm.validateField('newPasswordCheck');
                    }
                    callback();
                }
            } else {
                callback();
            }
        };
        //密码二次确认
        const validatePass2 = (rule, value, callback) => {
            if (this.changePasswordVisible) {
                if (value === undefined || value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.changePasswordForm.newPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        return {
            //左侧菜单信息
            menuList: [],
            //登陆表单信息
            loginForm: {
                phoneNumber: "", //手机号
                password: "" //密码
            },
            //登陆表单验证
            loginRules: {
                phoneNumber: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }]
            },
            dialogFormVisible: false, //登录弹框显示界面
            changePasswordVisible: false, //修改密码页面
            changePasswordForm: {
                oldPassword: '', //旧密码
                newPassword: '', //新密码
                newPasswordCheck: '' //确认密码
            },
            //修改密码表单验证
            changePasswordRules: {
                oldPassword: [{
                    required: true,
                    message: '请输入旧密码',
                    trigger: 'blur'
                }],
                newPassword: [{
                    required: true,
                    validator: validatePass,
                    trigger: 'blur'
                }],
                newPasswordCheck: [{
                    required: true,
                    validator: validatePass2,
                    trigger: 'blur'
                }]
            }
        }
    },
    methods: {
        //获取左侧公共菜单列表
        setPublicMenu() {
            this.$axios({
                method: 'get',
                url: '/api/authCenter/auth/publicRouter',
            }).then(res => {
                if (res.data.code !== 0) {
                    this.$message.error(res.data.msg)
                } else {
                    this.menuList = list2singleObject(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //登录
        login() {
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    // 登录逻辑
                    this.$axios({
                        method: 'post',
                        url: '/api/user/logByPhone',
                        data: this.loginForm
                    }).then(res => {
                        if (res.data.code !== 0) {
                            this.$message.error(res.data.msg);
                        } else {
                            this.$store.commit('token', res.data.data.token);
                            this.$store.commit('userId', res.data.data.id);
                            this.$store.commit('userName', res.data.data.userName);
                            this.$store.commit('phoneNumber', res.data.data.phoneNumber);
                            this.$store.commit('imgUrl', res.data.data.imgUrl);
                            this.$store.commit('roleList', res.data.data.roleList);
                            this.$store.commit('authList', res.data.data.authList);
                            this.menuList = list2singleObject(res.data.data.authList);
                            this.$message.success(res.data.msg);
                            this.dialogFormVisible = false;
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    return false;
                }
            });
        },
        //登出
        handleCommand(command) {
            if (command === 'logout') {
                this.$axios({
                    method: 'get',
                    url: '/api/user/logOut',
                    data: this.loginForm
                }).then(res => {
                    if (res.data.code !== 0) {
                        this.$message.error(res.data.msg);
                    } else {
                        this.$store.commit('token', '');
                        this.$store.commit('userId', '');
                        this.$store.commit('userName', '');
                        this.$store.commit('phoneNumber', '');
                        this.$store.commit('imgUrl', '');
                        this.$store.commit('roleList', []);
                        this.$store.commit('authList', []);
                        this.$store.commit('genshinMainUid', '');
                        this.setPublicMenu();
                        this.$message.success(res.data.msg);
                        this.menu("/");
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else if (command === 'changePassword') {
                this.changePasswordVisible = true;
            }
        },
        //跳转路由
        menu(commentName) {
            this.$router.push(commentName);
        },
        //修改密码
        changePassword() {
            this.$refs["changePasswordForm"].validate((valid) => {
                if (valid) {
                    this.$axios({
                        method: 'post',
                        url: '/api/authCenter/user/changePassWord',
                        data: this.changePasswordForm
                    }).then(res => {
                        if (res.data.code !== 0) {
                            this.$message.error(res.data.msg);
                        } else {
                            this.$message.success(res.data.msg);
                            this.changePasswordVisible = false;
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    return false;
                }
            });
        },
        //关闭修改密码
        quitChangePassword() {
            //恢复表单数据
            this.changePasswordForm = {
                oldPassword: '', //旧密码
                newPassword: '', //新密码
                newPasswordCheck: '' //确认密码
            };
            this.$refs.changePasswordForm.clearValidate();
            this.changePasswordVisible = false;
        }

    },
    computed: {
        loginUserId() {
            return this.$store.state.userId;
        },
        loginUserName() {
            return this.$store.state.userName;
        },
    },
    created() {
        if (this.$store.state.userId !== '') {
            this.menuList = list2singleObject(this.$store.state.authList);
        } else {
            this.setPublicMenu();
        }
    },
}
</script>

<style>
</style>
