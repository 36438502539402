//将含有id及pid的列表转换为含有child的单层结构体
export function list2singleObject(arr) {
    const newArr = [];
    arr.forEach(item => {
        if (item.pid === '0' && item.authType === '0') {
            newArr.push({
                child: [],
                ...item
            });
        }
    });
    newArr.sort((a, b) => {
        return a.seq - b.seq;
    })
    newArr.forEach(parent => {
        arr.map(item => {
            if (item.pid === parent.id && item.authType === '0') {
                parent.child.push({...item});
            }
        });
        parent.child.sort((a, b) => {
            return a.seq - b.seq
        })
    });
    return newArr;
}

//获取当前时间
export function getNowFormatDate() {
    return formatDate(new Date());
}

//日期格式化
export function formatDate(date) {
    const separator1 = "-";
    const separator2 = ":";
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const strHours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const strMin = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const strSec = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return date.getFullYear() + separator1 + month + separator1 + strDate + " " + strHours + separator2 + strMin + separator2 + strSec;
}