import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

//使用vuex插件
Vue.use(Vuex)
export default new Vuex.Store({
    //全局状态
    state: {
        token: '',
        userId: '',
        userName: '',
        phoneNumber: '',
        imgUrl: '',
        roleList: [],
        authList: [],
        genshinMainUid: '',
    },
    //同步数据提交
    mutations: {
        token(state, param) {
            state.token = param
        },
        userId(state, param) {
            state.userId = param
        },
        userName(state, param) {
            state.userName = param
        },
        phoneNumber(state, param) {
            state.phoneNumber = param
        },
        imgUrl(state, param) {
            state.imgUrl = param
        },
        roleList(state, param) {
            state.roleList = param
        },
        authList(state, param) {
            state.authList = param
        },
    },
    //引入持久化组件
    plugins: [createPersistedState({
        storage: window.sessionStorage, // 使用 sessionStorage 而不是 localStorage,关闭浏览器时自动清除缓存
    }),]
})
